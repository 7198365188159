<template>
  <LiefengContent>
    <template v-slot:title>客服管理</template>
    <template v-slot:toolsbarRight>
      <Input :maxlength="20" v-model.trim="searchData.creator" placeholder="通过姓名查询" style="width: 200px;margin-right: 10px"></Input>
      <Input :maxlength="20" v-model.trim="searchData.mobile" placeholder="通过联系电话查询" style="width: 200px;margin-right: 6px"></Input>
      <Button type="primary" icon="ios-search" @click="getList()">搜索</Button>
      <Button type="success" icon="ios-refresh" @click="rest">重置</Button>
      <Button type="primary" icon="md-add" @click="addEvent">我要反馈</Button>
      <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />

      <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchData" :label-colon="true">
            <FormItem label="姓名">
              <Input
                v-model.trim="searchData.creator"
                placeholder="请输入姓名"
                :maxlength="20"
              ></Input>
            </FormItem>
            <FormItem label="联系电话">
              <Input
                :maxlength="20"
                v-model.trim="searchData.mobile"
                placeholder="请输入联系电话"
              ></Input>
            </FormItem>
            <FormItem label="工号">
              <Input
                :maxlength="20"
                v-model.trim="searchData.staffCode"
                placeholder="请输入档案编号"
                style="width: 100%"
              />
            </FormItem>
            <FormItem label="反馈类型">
               <Select v-model="searchData.suggestionsType" placeholder="请选择反馈类型" clearable>
                   <Option
                  :value="item.dictKey"
                  v-for="(item, index) in sugList"
                  :key="index"
                  >{{ item.dictValue }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="优先级">
              <Select v-model="searchData.priority" placeholder="请选择优先级" clearable>
                <Option value="1">一般</Option>
                <Option value="2">紧急</Option>
              </Select>
            </FormItem>
             <FormItem label="状态">
               <Select v-model="searchData.status" placeholder="请选择状态" clearable>
                  <Option value="1">待回复</Option>
                  <Option value="2">处理中</Option>
              </Select>
            </FormItem>
             <FormItem label="方便电话时间">
               <Select v-model="searchData.callTime" placeholder="请选择方便电话时间" clearable>
                  <Option value="1">任何时间</Option>
                  <Option value="2">不方便接</Option>
                  <Option value="3">工作时间接</Option>
              </Select>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false"
              >取消</Button
            >
            <Button type="primary" @click="getList()">查询</Button>
          </div>
        </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="pageNumber"
        @hadlePageSize="getList"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
      ></LiefengTable>
      <!-- 我要反馈 -->
      <LiefengModal
        title="我要反馈"
        height="300px"
        :value="addserviceModal"
        @input="addserviceModalFn"
      >
        <template v-slot:contentarea>
          <Form
            :model="serviceForm"
            :label-width="120"
            ref="serviceForm"
            :rules="ruleValidate"
          >
            <FormItem label="反馈类型" prop="suggestionsType">
              <Select v-model="serviceForm.suggestionsType">
                <Option
                  :value="item.dictKey"
                  v-for="(item, index) in sugList"
                  :key="index"
                  >{{ item.dictValue }}</Option
                >
              </Select>
            </FormItem>
            <FormItem label="优先级" prop="priority">
              <RadioGroup v-model="serviceForm.priority">
                <Radio label="1">一般</Radio>
                <Radio label="2">紧急</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="描述" prop="describe">
              <Input
                :maxlength="400"
                v-model.trim="serviceForm.describe"
                type="textarea"
                show-word-limit
                :autosize="{ minRows: 2, maxRows: 5 }"
              />
            </FormItem>
            <FormItem label="方便电话时间">
              <RadioGroup v-model="serviceForm.callTime">
                <Radio label="1">任何时间</Radio>
                <Radio label="2">不方便接</Radio>
                <Radio label="3">工作时间接</Radio>
              </RadioGroup>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="addserviceModalFn(false)">取消</Button>
          <Button
            style="margin-left: 10px"
            type="primary"
            @click="saveservice('serviceForm')"
          >
            确定</Button
          >
        </template>
      </LiefengModal>
      <!-- 反馈跟进 -->
      <LiefengModal title="反馈跟进" :value="backModal" @input="backModalFn">
        <template v-slot:contentarea>
          <Form ref="backForm" :model="backForm" :label-width="110">
            <FormItem label="责任人">
              <Input
                :maxlength="20"
                type="text"
                v-model.trim="backForm.staffName"
              />
            </FormItem>
            <FormItem label="预估工作量">
              <Input
                :maxlength="20"
                type="text"
                v-model.trim="backForm.replyTime"
                placeholder="小时"
              />
            </FormItem>
            <FormItem label="答复日期">
              <DatePicker
                type="date"
                v-model="backForm.workload"
                transfer
              ></DatePicker>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="backModalFn(false)">取消</Button>
          <Button style="margin-left: 10px" type="primary" @click="saveBack">
            确定</Button
          >
        </template>
      </LiefengModal>
      <!-- 详情 -->
      <LiefengModal
        title="详情"
        width="900"
        height="calc(100vh - 100px)"
        :value="detailModal"
        @input="detailModalFn"
      >
        <template v-slot:contentarea>
          <Steps :current="steps">
            <Step title="待回复"></Step>
            <Step title="处理中"></Step>
            <Step title="已反馈"></Step>
            <Step title="已结束"></Step>
          </Steps>
          <Card :bordered="false" style="margin-top: 20px">
            <Row>
              <Col span="3"
                ><span style="font-weight: bold; margin-right: 5px">工号:</span
                >{{ detailList.staffCode }}</Col
              >
              <Col span="3"
                ><span style="font-weight: bold; margin-right: 5px">姓名:</span
                >{{ detailList.creator }}</Col
              >
              <Col span="6"
                ><span style="font-weight: bold; margin-right: 5px"
                  >联系电话:</span
                >{{ detailList.mobile }}</Col
              >
              <Col span="6"
                ><span style="margin-right: 5px">所属机构:</span
                >{{ detailList.orgName }}</Col
              >
              <Col span="6"
                ><span style="margin-right: 5px">反馈类型:</span
                >{{ detailList.suggestionsType }}</Col
              >
            </Row>
            <Row style="margin-top: 20px">
              <Col span="6"
                ><span style="font-weight: bold; margin-right: 5px"
                  >方便电话时间:</span
                >{{ detailList.callTime }}</Col
              >
              <Col span="6"
                ><span style="font-weight: bold; margin-right: 5px"
                  >提交时间:</span
                >{{ detailList.gmtCreate }}</Col
              >
              <Col span="6"
                ><span style="font-weight: bold; margin-right: 5px"
                  >优先级:</span
                >{{ detailList.priority }}</Col
              >
              <Col span="6"
                ><span style="font-weight: bold; margin-right: 5px"
                  >当前状态:</span
                >{{ detailList.status }}</Col
              >
            </Row>
          </Card>
          <List size="small" :split="false">
            <template v-for="(item, index) in detailList.cscRecord">
              <ListItem :key="index" v-if="item.userType === '1'">
                <div>
                  <span style="color: #2d9aff;"
                    >{{ item.csName }}
                  </span>
                  ———
                  <span>{{
                    item.gmtCreate
                  }}</span>
                    <p style="text-indent: 2em">{{ item.content }}</p>
                </div>
              </ListItem>
              <ListItem
                v-else
                :key="index"
              >
                <div>
                  <span
                    style="
                      color: #2d9aff;
                    "
                    >{{ item.csName }}
                  </span>
                  ———
                   <span>{{
                    item.gmtCreate
                  }}</span>
                  <p style="text-indent: 2em">{{ item.content }}</p>
                </div>
              </ListItem>
            </template>
            <template slot="footer">
              <Input
                v-model.trim="recordVal"
                type="textarea"
                show-word-limit
                :rows="2"
                :maxlength="200"
              />
              <Row style="margin-top: 20px">
                <Col span="2" offset="22"
                  ><Button type="primary" @click="recordEvent"
                    >回复</Button
                  ></Col
                >
              </Row>
            </template>
          </List>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/sponsorservices")
import { format } from "@/utils/formatTime";
import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  watch: {
    selectQuery(newV) {
      if (!newV) {
        this.searchData.creator = '';
            this.searchData.mobile = '';
            this.searchData.staffCode = '';
            this.searchData.suggestionsType = '';
            this.searchData.priority = '';
            this.searchData.status = '';
            this.searchData.callTime = '';
      }
    },
  },
  data() {
    return {
      selectQuery: false,
      loading: false,
      total: 0,
      pageNumber: 1,
      pageSize: 20,
      addserviceModal: false,
      backModal: false,
      detailModal: false,
      sugList: [],
      steps: 0,
      record: false,
      recordVal: "",
      searchData: {
        status: "",
        page: "",
        pageSize: 20,
        creator: '',
        mobile: '',
        staffCode: '',
        suggestionsType: '',
        priority: '',
        callTime: ''
      },
      detailList: {},

      tableData: [],
      talbeColumns: [
        {
          title: "工号",
          key: "staffCode",
          width: 120,
          align: "center",
        },
        {
          title: "姓名",
          key: "creator",
          width: 120,
          align: "center",
        },
        {
          title: "联系电话",
          key: "mobile",
          width: 150,
          align: "center",
        },
        {
          title: "反馈类型",
          key: "suggestionsType",
          minWidth: 140,
          align: "center",
        },
        {
          title: "优先级",
          key: "priority",
          width: 120,
          align: "center",
        },
        // {
        //   title: "描述",
        //   key: "name",
        //   width: 110,
        //   align: "center",
        // },
        {
          title: "方便电话时间",
          key: "callTime",
          width: 140,
          align: "center",
        },
        {
          title: "提交时间",
          key: "gmtCreate",
          width: 180,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 220,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.detail(params.row.suggestionsId);
                    },
                  },
                },
                "详情"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "success",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.serviceback(params.row);
                    },
                  },
                },
                "反馈跟进"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },

                  on: {
                    click: () => {
                      this.remove(params.row.suggestionsId);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      backForm: {
        staffName: "",
        replyTime: "",
        workload: "",
        suggestionsId: "",
        recordId: "",
      },
      serviceForm: {
        suggestionsType: "",
        priority: "",
        callTime: "",
        describe: "",
        creatorId: "",
        oemCode: "",
        appCode: "",
        userType: 2,
      },
      ruleValidate: {
        describe: [
          {
            required: true,
            message: "描述不能为空",
            trigger: "blur",
          },
        ],
        priority: [
          {
            required: true,
            message: "优先级不能为空",
            trigger: "change",
          },
        ],
        suggestionsType: [
          {
            required: true,
            type: "string",
            message: "反馈类型不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "工号",
            "姓名",
            "联系电话",
            "反馈类型",
            "优先级",
            "方便电话时间",
            "提交时间",
            "状态",
          ];
          let filterVal = [
            "staffCode",
            "creator",
            "mobile",
            "suggestionsType",
            "priority",
            "callTime",
            "gmtCreate",
            "status",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "反馈列表"
          );
        },
      });
    },
    addserviceModalFn(status) {
      this.addserviceModal = status;
    },
    backModalFn(status) {
      this.backModal = status;
    },
    detailModalFn(status) {
      this.detailModal = status;
    },
    rest() {
       this.searchData.creator = '';
        this.searchData.mobile = '';
        this.searchData.staffCode = '';
        this.searchData.suggestionsType = '';
        this.searchData.priority = '';
        this.searchData.status = '';
        this.searchData.callTime = '';
      this.getList();
    },
    getList(data) {
      this.loading = true;
      if (data) {
        this.pageNumber = data.page;
        this.searchData.page = data.page;
        this.searchData.pageSize = data.pageSize;
      } else {
        this.searchData.page = this.pageNumber;
        this.searchData.pageSize = this.pageSize;
      }

      this.$get(
        "/old/api/finger/csc/selectSuggestionPage",
        this.searchData
      ).then((res) => {
        if (res.code === "200") {
          res.dataList.forEach((item) => {
            item.gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm");
            item.priority = item.priority == 1 ? "一般" : item.priority == 2 ?"紧急" : '';
            item.callTime =
              item.callTime == 1
                ? "任何时间"
                : item.callTime == 2
                ? "不方便接"
                : item.callTime == 3
                ? "工作时间接"
                : "";
            item.status =
              item.status == 1
                ? "待回复"
                : item.status == 2
                ? "处理中"
                : item.status == 3
                ? "已反馈"
                : item.status == 4
                ? "已结束"
                : '';
            // 1待回复 2处理中 3已反馈 4 已结束
          });
          this.total = res.maxCount;
          
          this.tableData = res.dataList;
          this.loading = false;
          this.selectQuery = false;
          this.searchData.creator = '';
            this.searchData.mobile = '';
            this.searchData.staffCode = '';
            this.searchData.suggestionsType = '';
            this.searchData.priority = '';
            this.searchData.status = '';
            this.searchData.callTime = '';
        } else {
          this.$Message.error({
            background: true,
            content: "数据获取失败",
          });
          this.loading = false;
        }
      });
    },
    addEvent() {
      this.addserviceModal = true;
    },
    getCode(data) {
      if (data) {
        this.$get("/old/api/common/sys/findDictByDictType", {
          dictType: data,
        })
          .then((res) => {
            if (data === "SUIYUE") {
              this.serviceForm.appCode = res.dataList;
            } else {
              this.sugList = res.dataList;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$Message.error("未知的dictype");
      }
    },
    saveservice(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.serviceForm.userType = 2;
          this.serviceForm.creatorId =
            parent.vue.loginInfo.userinfo.custGlobalId;
          this.serviceForm.oemCode = parent.vue.oemInfo.oemCode;
          this.$post(
            "/old/api/finger/csc/saveSuggestions",
            this.serviceForm
          ).then((res) => {
            if (res.code === "200") {
              this.$Message.success("操作成功");
              this.getList();
              this.addserviceModal = false;
              this.serviceForm = {};
            }
          });
        }
      });
    },
    setSelection() {},
    remove(data) {
      if (data) {
        // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
        // console.log(this.total)
        // console.log(this.pageSize)
        // console.log(this.pageNumber)
        let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
        this.pageNumber =
          this.pageNumber > totalPage ? totalPage : this.pageNumber;
        // console.log(this.pageNumber)
        this.pageNumber = this.pageNumber < 1 ? 1 : this.pageNumber;
        // console.log(this.pageNumber)

        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认删除数据</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
            this.$post("/old/api/finger/csc/deleteBysuggeationId", {
              suggereationId: data,
            })
              .then((res) => {
                if (res.code === "200") {
                  this.$Message.success("操作成功");
                  let data = {
                    pageSize: this.pageSize,
                    page: this.pageNumber,
                  };
                  this.getList(data);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
        });
      } else {
        this.$Message.error("请先选择数据");
      }
    },
    detail(data) {
      this.detailModal = true;
      if (data) {
        this.$get("/old/api/finger/csc/selectBySuggestionsId", {
          suggestionsId: data,
        })
          .then((res) => {
            // console.log(res);
            this.detailList = res.data;
            this.detailList.priority =
              this.detailList.priority == 1 ? "一般" : this.detailList.priority == 2 ? "紧急" : '';
            this.detailList.callTime =
              this.detailList.callTime == 1
                ? "任何时间"
                : this.detailList.callTime == 2
                ? "不方便接"
                : this.detailList.callTime == 2
                ? "工作时间接"
                : "无";

            this.steps = this.detailList.status * 1 - 1;
            this.detailList.status =
              this.detailList.status == 1
                ? "待回复"
                : this.detailList.status == 2
                ? "处理中"
                : this.detailList.status == 3
                ? "已反馈"
                : this.detailList.status == 4 
                ? "已结束"
                : '';
            this.detailList.gmtCreate = format(
              this.detailList.gmtCreate,
              "yyyy-MM-dd HH:mm"
            );
            this.detailList.cscRecord.forEach((item) => {
              item.gmtCreate = format(item.gmtCreate, "yyyy-MM-dd HH:mm");
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$Message.error("请先选择数据");
      }
    },
    serviceback(data) {
      let suggestionsId = data.suggestionsId;
      this.backModal = true;
      this.$post("/old/api/finger/csc/selectHandle", {
        suggestionsId: suggestionsId,
      })
        .then((res) => {
          if (res.code === "200") {
            if (res.data === null) {
              this.backForm = {
                suggestionsId: suggestionsId,
                recordId: data.cscHandleVoList[0].id,
              };
            } else {
              this.backForm = res.data;
              this.backForm.suggestionsId = suggestionsId;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveBack() {
      let params = {
        ...this.backForm,
        workload: this.backForm.workload
          ? this.$core.formatDate(
              new Date(this.backForm.workload),
              "yyyy-MM-dd"
            )
          : "",
      };
      this.$post("/old/api/finger/csc/saveHandle", params)
        .then((res) => {
          if (res.code === "200") {
            this.$Message.success("操作成功");
            this.getList();
            this.backModal = false;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    recordEvent() {
      // 定位到回复

      if (this.recordVal && this.recordVal !== "") {
        const data = {
          content: this.recordVal,
          csId: parent.vue.loginInfo.userinfo.custGlobalId,

          userType: "2",
          suggestionsId: this.detailList.suggestionsId,
          oemCode: parent.vue.oemInfo.oemCode,
        };
        this.$post("/old/api/finger/csc/saveRecord", data)
          .then((res) => {
            this.recordVal = "";
            this.$Message.success("回复成功");
            this.detailModalFn(false);
            this.getList();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$Message.error("回复不能为空");
      }
      this.record = !this.record;
    },
  },
  created() {
    this.getList();
    // 获取客户反馈类型
    this.getCode("CSC_SUGGESTIONS_TYPE");
    // 获取应用编码；（数据字典）
    this.getCode("SUIYUE");
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal,
  },
};
</script>
    
<style scoped lang='less'>
.ivu-form-item-required {
  margin-bottom: 20px;
}
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
     margin-bottom: 0;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>